import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

// Authentication imports
import { useMsal } from '@azure/msal-react'
import { tokenRequest } from '../utils/authConfig'
import { callMsGraph } from '../utils/graph'
import { CallApi } from '../utils/api'

// React components
import { AlertMappedDataTable } from '../components/alerts/AlertMappedDataTable'
import { AlertRawDataTable } from '../components/alerts/AlertRawDataTable'
import { ActorCard } from '../components/alerts/ActorCard'
import { EmploymentStatus } from '../components/alerts/EmploymentStatus'
import { IpLocation } from '../components/alerts/IpLocation'
import { OtherAlertsFromActor } from '../components/alerts/OtherAlertsFromActor'
import { Feedback } from '../components/alerts/Feedback'
import { SendToManager } from '../components/alerts/SendEmailToManager'

// CSS components
import { Grid, GridItem, Heading, Flex, Divider, Center, Wrap, Stack } from '@chakra-ui/react'

export const Alerts = () => {

	// Get instanceGuid from URL
	let { instanceGuid } = useParams()

	const placeholderAlertGuid = '8039f263-29f5-46ba-90e2-2f2dc2be7506'

	if (instanceGuid == undefined) {
		// Set placeholder instanceGuid
		instanceGuid = placeholderAlertGuid

		// Set url path to include example instanceGuid
		window.history.pushState({}, '', '/alerts/' + instanceGuid)

	}

	const { instance, accounts } = useMsal()
	const [alertDetails, setAlertDetails] = useState<any>(null)
	const [mappedData, setMappedData] = useState<any>(null)
	const [rawData, setRawData] = useState<any>(null)
	const [feedback, setFeedback] = useState<any>(true)
	const [employmentTerminated, setEmploymentTerminated] = useState<boolean>(true)
	const [locationData, setLocationData] = useState<any>(null)

	const [loading, setLoading] = useState<boolean>(true)
	const [feedbackLoading, setFeedbackLoading] = useState<boolean>(true)

	useEffect(() => {
		// Silently acquires an access token which is then attached to a request for the dotnet backend api
		instance.acquireTokenSilent({
			...tokenRequest,
			account: accounts[0]
		}).then((response) => {
			CallApi('/alerts/getAlertDetails/' + instanceGuid, response.accessToken, 'Get')
				.then((response: any) => {
					console.log(JSON.parse(response)[0].Data)
					console.log(JSON.parse(response)[0].RawData)
					setMappedData(JSON.parse(response)[0].Data)
					setRawData(cleanRawData(JSON.parse(response)[0].Data, JSON.parse(response)[0].RawData))
					setEmploymentTerminated(!JSON.parse(response)[0].TerminationDate)
					setLocationData({
						"OfficeLocation": JSON.parse(response)[0].BuildingCountry,
						"ActivityLocation": "Unknown", // TODO: Make this dynamic
					})
					setLoading(false)
				})
			
			// Perform query to get feedback data in parallel
			CallApi('/alerts/getAlertFeedbackData/' + instanceGuid, response.accessToken, 'Get')
			.then((response: any) => {
				if(JSON.parse(response).length == 0)
				{
					setFeedback({
						"FeedbackTime": null,
						"InstanceId": instanceGuid,
						"SourceName": null,
						"Author": null,
						"Value": null,
					})
				}
				else
				{
					setFeedback({
						"FeedbackTime": JSON.parse(response)[0].FeedbackTime,
						"InstanceId": instanceGuid,
						"SourceName": JSON.parse(response)[0].SourceName,
						"Author": JSON.parse(response)[0].SourceUser,
						"Value": JSON.parse(response)[0].Value,
					})
				}
				setFeedbackLoading(false)
			})
		})
	}, []) // Pass empty array to avoid re-rendering on every change (only runs this code once)

	// Remove values from rawData which are already in mappedData
	function cleanRawData(mappedData:any, rawData:any)
	{
		let arraySorter = (_key: string, value: any) => {
			if (Array.isArray(value)) {
				return value.sort();
			} else {
				return value;
			}
		}


		// Remove raw data keys which have the same value as the mapped data keys
		// O(n^2) since key names are different in each object
		for (let key in mappedData)
		{
			for (let key2 in rawData)
			{
				// If the mapped data value is an array, sort it and compare it to the raw data value
				if (mappedData[key][0] == "[" && !mappedData[key].includes("SecMonFeedback"))
				{
					if (typeof rawData[key2] === 'object' && rawData[key2] !== null)
					{
						if (JSON.stringify(JSON.parse(mappedData[key]).sort()) == JSON.stringify(rawData[key2], arraySorter))
						{
							delete rawData[key2]
						}

					}
				}
				// If the mapped data value is a string, compare it to the raw data value
				if (mappedData[key] == rawData[key2])
				{
					delete rawData[key2]
				}
			}
		}
		return rawData
	}

	function RawDataTitle () {
		if(!loading) {
			return <Heading size='sm' ml={4} mb={2} >Raw Data - All other output fields generated by this alert</Heading>
		}
		else {
			return null
		}
	}

	function MappedDataTitle () {
		if(!loading) {
			return <Heading size='md' mb={2} >{mappedData["alert_title"]}</Heading>
		}
		else {
			return <Heading size='md' mb={2}>Loading data for instanceGuid {instanceGuid}...</Heading>
		}
	}

	return (
		<>
			{instanceGuid == placeholderAlertGuid ? <Heading size='md' mb={2}>This is an example instance guid, please specify your instance guid via the url.</Heading> : null}
			{MappedDataTitle()}

			<Grid
				//h='1200px'
				templateRows='repeat(2, 1fr)'
				templateColumns='repeat(10, 1fr)'
				gap={4}
			>

				<GridItem rowSpan={2} colSpan={6}>
					<Flex direction={{ base: 'row', md: 'column' }}>
						<AlertMappedDataTable mappedData={mappedData} loading={loading} />
						<Divider my={8} />
						{RawDataTitle()}
						<AlertRawDataTable rawData={rawData} loading={loading} />
					</Flex>

				</GridItem>
				<GridItem rowSpan={2} colSpan={4} bg='' py={6}>
					<Stack direction={{ base: 'row', md: 'column' }} gap={4}>
						<Center>
							<Flex flexWrap={'wrap'} direction='row' justify='center' align='start' gap={4} maxW={'90%'}>
								<ActorCard instanceGuid={instanceGuid}/>
								<Flex direction='column' justify='' align='center' gap={4}>
									<EmploymentStatus employmentStatus={employmentTerminated}/>
									<IpLocation locationData={locationData} loading={loading}/>
									<Feedback feedback={feedback} loading={feedbackLoading}/>
								</Flex>
							</Flex>
						</Center>

						<OtherAlertsFromActor instanceGuid={instanceGuid} />
						<SendToManager instanceGuid={instanceGuid} />


					</Stack>

				</GridItem>

			</Grid>


		</>
	)
}